export function showCepError(cepInput, cepError, message) {
    cepInput.addClass("is-invalid");
    if (cepError.length === 0) {
        cepInput.after(
            `<div id="cep-error" class="invalid-feedback">${message}</div>`
        );
    } else {
        cepError.text(message).show();
    }
}

export function hideCepError(cepInput, cepError) {
    cepInput.removeClass("is-invalid");
    cepError.hide();
}
