Eloca.behaviors.password = function (context) {
    $(".senha-forte", context).on("focus", function () {
        $(".js-senha-info", context).removeClass("d-none");
    });

    $(".senha-forte", context).on("keyup", function () {
        const senha = this.value;

        let forca = 0;

        if (senha.length >= 4 && senha.length <= 7) {
            forca += 10;
        }

        if (senha.length > 7 && senha.match(/[0-9]+/)) {
            forca += 25;
        }

        if (senha.length >= 5 && senha.match(/[a-z]+/)) {
            forca += 10;
        }

        if (senha.length >= 6 && senha.match(/[A-Z]+/)) {
            forca += 20;
        }

        if (senha.length >= 7 && senha.match(/[@#$%&;*]+/)) {
            forca += 25;
        }

        mostraForca(forca);
        mostraCaracteres(senha);

        if (forca >= 70) {
            $(".js-btn-salvar-senha").removeAttr("disabled");
        } else {
            $(".js-btn-salvar-senha").attr("disabled", true);
        }
    });

    $(".js-btn-salvar-senha", context).on("click", function() {
        $("#senhaModal").modal("toggle");
    });

    $(".js-btn-cancelar-senha", context).on("click", function() {
        $("input[name='password']").val('');
        $("input[name='password_confirmation']").val("");
        $("input[name='password']").trigger("keyup");
    });

    $("#togglePassword", context).on("mousedown touchstart", function () {
        $(".senha-forte").attr("type", "text");
        $(".js-fa-eye", context).addClass("d-none");
        $(".js-fa-eye-slash", context).removeClass("d-none");
    });
    $("#togglePassword", context).on(
        "mouseup mouseleave touchend",
        function () {
            $(".senha-forte").attr("type", "password");
            $(".js-fa-eye", context).removeClass("d-none");
            $(".js-fa-eye-slash", context).addClass("d-none");
        }
    );

    function mostraForca(forca) {
        $(".js-forca-senha div", context)
            .removeClass("bg-danger")
            .removeClass("bg-primary")
            .removeClass("bg-success");
        if (forca == 0) {
            return;
        }
        if (forca < 30) {
            $(".js-forca-senha .js-senha-fraca", context).addClass("bg-danger");
        } else if (forca < 70) {
            $(".js-forca-senha .js-senha-fraca", context).addClass(
                "bg-primary"
            );
            $(".js-forca-senha .js-senha-media", context).addClass(
                "bg-primary"
            );
        } else {
            $(".js-forca-senha div", context).addClass("bg-success");
        }
    }

    function mostraCaracteres(senha) {
        $(".js-senha-info p", context).removeClass("text-success");
        $(".js-senha-info p span", context).removeClass("bg-success");

        if (senha.length > 7) {
            $(".js-senha-info .js-senha-minimo", context).addClass(
                "text-success"
            );
        }
        if (senha.match(/[0-9]+/)) {
            $(".js-senha-info .js-senha-numero", context).addClass(
                "text-success"
            );
        }
        if (senha.match(/[a-z]+/)) {
            $(".js-senha-info .js-senha-minuscula", context).addClass(
                "text-success"
            );
        }
        if (senha.match(/[A-Z]+/)) {
            $(".js-senha-info .js-senha-maiuscula", context).addClass(
                "text-success"
            );
        }
        if (senha.match(/[@#$%&;*]+/)) {
            $(".js-senha-info .js-senha-especiais", context).addClass(
                "text-success"
            );
        }
    }
};
