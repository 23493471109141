const { default: axios } = require("axios");
const moment = require("moment");

Eloca.behaviors.carrinho = function (context) {
    // Plus button
    window.animacao = true;
    $(".js-btn-qtd").attr("disabled", false);

    $(".js-input-quantidade", context).on("blur", function () {
        const numberPattern = /^\d+$/;
        let quant = $(this).val();
        if (!quant.match(numberPattern)) {
            quant = 1;
            $(this).val("1");
        }

        if (quant < 1) {
            const rota = $(this).data("remove");
            if (rota) {
                window.location = rota;
            } else {
                $(this).val("1");
            }
        } else {
            setTimeout(function () {
                const form = document.querySelector(".js-carrinho-form");
                if (form) {
                    form.submit();
                } else {
                    $(".js-btn-qtd").attr("disabled", false);
                }
            }, 200);
        }
    });

    $(".btn-qtd-plus", context).on("click", function () {
        const box = $(this).closest(".js-box-quantidade-produto");
        const quant = $(".js-input-quantidade", box).val();
        $(".js-input-quantidade", box)
            .val(parseInt(quant) + 1)
            .trigger("change");
            $(this).attr('disabled', true);
            setTimeout(function(){

                const form = document.querySelector(".js-carrinho-form");
                if (form) {

                    form.submit();
                } else {

                    $(".js-btn-qtd").attr("disabled", false);
                }
            }, 200);
    });

    // Minus button
    $(".btn-qtd-minus", context).on("click", function () {
        const box = $(this).closest(".js-box-quantidade-produto");
        const quant = $(".js-input-quantidade", box).val();
        const rota = $(this).data("remove");

        if (quant > 1) {
            $(".js-input-quantidade", box)
                .val(parseInt(quant) - 1)
                .trigger("change");

            $(this).attr("disabled", true);

            setTimeout(function () {
                const form = document.querySelector(".js-carrinho-form");
                if (form) {
                    form.submit();
                } else {
                    $(".js-btn-qtd").attr("disabled", false);
                }
            }, 200);
        } else if (rota) {
            window.location = rota;
        } else {
            $(this).val("1");
        }

    });

    //loja
    $(".js-bloco-loja-mista input:radio", context).on("change", function () {
        if (this.value == "assinatura") {
            $(".js-produto-faixas", context).addClass("d-none");
            $(".js-preco-faixa-selecionada", context).addClass("d-none");
        } else {
            $(".js-produto-faixas", context).removeClass("d-none");
            $(".js-preco-faixa-selecionada", context).addClass("d-none");
        }
        $(".js-preco-produto", context).data("preco", $(this).data("preco"));

        if ($(".js-preco-faixa-selecionar option", context).length) {
            $(".js-preco-faixa-selecionar", context).val("").trigger("change");
        } else if ($(".js-preco-faixa-selecionar", context).length >= 1) {
            $(".js-preco-faixa-selecionar", context)
                .closest(".js-mini-bloco")
                .removeClass("option-selected");
            $(".js-preco-faixa-selecionar:checked", context).prop(
                "checked",
                false
            );
            //$(".js-preco-faixa-selecionar", context).trigger("change");
            $(".js-preco-faixa-selecionada .valor-preco-faixa-selecionada", context).html("");
            $(".js-preco-faixa-fator", context).html("");
            $(".js-preco-fator", context).addClass("d-none");
        }
        $(".js-data-devolucao-texto", context).html("");

        calculaPrecoAcessorios(context);
        atualizaPrecosAcessorios(context);
    });

    //upload arquivos vapor
    $(".js-file-input", context).on("change", function () {
        const input = this;
        const extensoesAceitas = $(this).data("extensao")
            ? $(this).data("extensao")
            : ["jpg", "jpeg", "png", "pdf", "webp", "mp4"];
        const url = $(this).val();
        const ext = url.substring(url.lastIndexOf(".") + 1).toLowerCase();
        const id = $(this).data("id");
        const inputBlock = $(this).closest(".js-custom-file-block");

        if (
            input.files &&
            input.files[0] &&
            extensoesAceitas.find((extensao) => extensao == ext)
        ) {
            const fileSize = input.files[0].size / 1000 / 1000;
            if (fileSize > 100) {
                $(input).addClass("file-error").removeClass("file-success");

                $(".js-file-name", inputBlock)
                    .removeClass("msg-success")
                    .addClass("msg-danger")
                    .html(
                        `<div class='msg-danger'>Documento não enviado. O tamanho excede o limite de 100MB.
                            <div class='small'> Tamanho do arquivo: ${fileSize.toFixed(
                                2
                            )} MB</div>
                        </div>`
                    );

                return;
            }

            $("#overlayer-loading").addClass("show-overlayer");

            Vapor.store(input.files[0], {
                baseURL: siteUrl("/site"),
                progress: (progress) => {
                    this.uploadProgress = Math.round(progress * 100);
                },
            }).then((response) => {
                $("#anexo-" + id, context).val(response.uuid);
                $(input).removeClass("file-error").addClass("file-success");
                $("#overlayer-loading").removeClass("show-overlayer");

                $(".js-file-name", inputBlock)
                    .removeClass("msg-danger")
                    .addClass("msg-success")
                    .html(
                        `<div class='msg-success'>Enviado com sucesso.</div>`
                    );
            });
        } else {
            $(input).addClass("file-error").removeClass("file-success");
            $(".js-file-name", inputBlock)
                .removeClass("msg-success")
                .addClass("msg-danger")
                .html(
                    `<div class='msg-danger'>Documento não enviado. O tipo de arquivo não é permitido.
                        <div class='small'>Arquivos permitidos: PDF, PNG, JPEG ou JPG.</div>
                    </div>`
                );
        }
    });

    // Produto Faixa
    $(".js-preco-faixa-selecionar", context).on("change", function () {
        let element = this;
        if ($(".js-preco-faixa-selecionar option:selected", context).length) {
            element = $(".js-preco-faixa-selecionar option:selected", context);
        }

        if ($(".js-scroll-faixa").length) {
            let headerHeight = 126;
            if ($("header").hasClass("onscroll")) {
                headerHeight = 56;
            }

            if (window.animacao)
                $("html, body").animate(
                    {
                        scrollTop:
                            $(".js-scroll-faixa").offset().top - headerHeight,
                    },
                    200
                );
        }

        const faixaValor = $(element).data("preco");
        const faixaFator = $(element).data("fator");
        const checkout_misto = $(".js-checkout-misto:checked", context).val();
        if (checkout_misto == "assinatura") {
            $(".js-btn-add-cart", context).removeAttr("disabled");
            $(".js-btn-loca-whatsapp", context).removeClass("disabled");
        }

        $(".js-preco-faixa-selecionar", context).removeClass("is-invalid");
        $(".js-mini-bloco", context).removeClass("is-invalid");

        if (typeof faixaValor == "undefined" || faixaValor == 0) {
            if (checkout_misto != "assinatura") {
                $(".js-btn-add-cart", context).attr("disabled", "disabled");
                $(".js-btn-loca-whatsapp", context).addClass("disabled");
            }
            $(".js-preco-faixa-selecionada .valor-preco-faixa-selecionada", context).html("");
            $(".js-preco-faixa-fator", context).html("");
            $(".js-preco-fator", context).addClass("d-none");
            $(".js-preco-faixa-selecionada", context).addClass("d-none");
        } else {
            $(".js-preco-faixa-selecionada", context).removeClass("d-none");
            $(".js-btn-add-cart", context).removeAttr("disabled");
            $(".js-btn-loca-whatsapp", context).removeClass("disabled");
            $(".js-preco-faixa-selecionada .valor-preco-faixa-selecionada", context).html(
                numeral(parseFloat(faixaValor)).format("$ 0,0.00")
            );
            if (
                typeof faixaFator != "undefined" &&
                parseFloat(faixaFator) > 0
            ) {
                $(".js-preco-fator", context).removeClass("d-none");
                $(".js-preco-faixa-fator", context).html(
                    numeral(
                        parseFloat(faixaValor) / parseFloat(faixaFator)
                    ).format("$ 0,0.00")
                );
            } else {
                $(".js-preco-fator", context).addClass("d-none");
                $(".js-preco-faixa-fator", context).html("");
            }
        }
        $(".js-preco-produto", context).data("preco", faixaValor);
        atualizaPrecosAcessorios(context);
        calculaPrecoSpot(context);
        calculaPrecoAcessorios(context);
        verificaDataDevolucao(context);
    });

    // Produto Spot
    $(".js-data-retirada, .js-data-devolucao, .js-horario-retirada, .js-horario-devolucao", context).on(
        "change",
        function () {
            calculaPrecoSpot(context);
        }
    );

    if ($(".js-data-retirada").length && $(".js-data-devolucao").length) {
        calculaPrecoSpot(context);
        $("#product-qtd", context).on("change", function () {
            calculaPrecoSpot(context);
        });
    }

    if (
        $(".js-data-programado", context).length &&
        $(".js-data-programado", context).val()
    ) {
        verificaDataDevolucao(context);
    }

    $(".js-data-programado", context).on("change", function () {
        verificaDataDevolucao(context);
    });

    $(".js-input-acessorios", context).on("change", function () {
        calculaPrecoAcessorios(context);
        calculaPrecoSpot(context);
    });

    //grupo faixa
    $(".js-mini-bloco-grupo", context).on("click", function () {
        const grupoId = $(this).data("grupo");
        $(".js-mini-bloco-grupo", context).removeClass("option-selected");
        $(this).addClass("option-selected");
        $(".js-mini-bloco", context).addClass("d-none");
        $(`.js-bloco-grupo-${grupoId}`, context).removeClass("d-none");
    });

    window.addToCart = function (context, button) {
        const preco = $(".js-preco-produto", context).data("preco");
        const produto = $(".js-preco-produto", context).data("produto");
        const dias = $(".js-preco-produto", context).data("dias");
        const orcamento = $(".js-preco-produto", context).data("orcamento");
        const tipo_produto_id = $(".js-preco-produto", context).data(
            "tipo_produto_id"
        );
        const url = $(".js-preco-produto", context).data("url");
        const quantidade = $(".js-input-quantidade", context).val();
        const checkout_misto = $(".js-checkout-misto:checked", context).val();
        const sendWhatsapp = $(button).data("whatsapp");
        const calculoAcessorio = $(".js-preco-produto", context).data(
            "calculo-acessorio"
        );

        let diasAcessorios = 1;

        let faixa_id = 0;
        let descricaoFaixa = "";
        if ($(".js-preco-faixa-selecionar", context).length == 1) {
            faixa_id = $(".js-preco-faixa-selecionar", context).val();
            descricaoFaixa = $(
                ".js-preco-faixa-selecionar option:selected",
                context
            ).data("descricao");
        } else if ($(".js-preco-faixa-selecionar", context).length > 1) {
            faixa_id = $(".js-preco-faixa-selecionar:checked", context).val();
            descricaoFaixa = $(
                ".js-preco-faixa-selecionar:checked",
                context
            ).data("descricao");
        }

        if (
            checkout_misto == "simples" &&
            (faixa_id == 0 ||
                typeof faixa_id == undefined ||
                faixa_id == undefined)
        ) {
            $(".js-preco-faixa-selecionar", context).addClass("is-invalid");
            $(".js-mini-bloco", context).addClass("is-invalid");
            return;
        } else if (checkout_misto == "assinatura") {
            faixa_id = 0;
        }

        const regex = /\d{4}-\d{2}-\d{2}/;
        let data = null;
        let dataFormatada = null;
        if ($(".js-data-programado", context).length) {
            data = $(".js-data-programado", context).val();
            if (regex.test(data)) {
                dataFormatada = moment(data).format("DD/MM/YYYY");
            } else {
                dataFormatada = data;
            }
        }

        //tipo produto PROGRAMADO

        let horario_programado = null;

        if (tipo_produto_id === 2) {
            horario_programado = $(".js-horario-programado", context).val();

            if (
                !sendWhatsapp &&
                (!data ||
                    moment(data, "DD/MM/YYYY") < moment().format("YYYY-MM-DD"))
            ) {
                $(".js-data-programado", context).addClass("is-invalid");
                return;
            }
        }

        let data_retirada,
            data_devolucao,
            horario_retirada,
            horario_devolucao = null;

        //tipo produto SPOT
        if (tipo_produto_id === 3) {
            data_retirada = $(".js-data-retirada", context).val();
            horario_retirada = $(".js-horario-retirada", context).val();
            data_devolucao = $(".js-data-devolucao", context).val();
            horario_devolucao = $(".js-horario-devolucao", context).val();

            if (!data_retirada && !sendWhatsapp) {
                $(".js-data-retirada", context).addClass("is-invalid");
                return;
            }
            if (!data_devolucao && !sendWhatsapp) {
                $(".js-data-devolucao", context).addClass("is-invalid");
                return;
            }

            if (regex.test(data_retirada)) {
                data_retirada = moment(data_retirada).format("DD/MM/YYYY");
            }
            if (regex.test(data_devolucao)) {
                data_devolucao = moment(data_devolucao).format("DD/MM/YYYY");
            }
            if (calculoAcessorio) {
                diasAcessorios = dias;
            }
        }

        if (sendWhatsapp) {
            return processaBotaoWhatsapp(
                context,
                $(button).data("whatsapp"),
                quantidade,
                preco,
                dataFormatada,
                data_retirada,
                data_devolucao,
                orcamento,
                button
            );
        }

        const timestamp = new Date().getTime();
        const itens = [];
        const item = {
            id: timestamp,
            produto_id: produto,
            preco,
            faixa_id,
            quantidade,
            data: dataFormatada,
            horario_programado,
            data_retirada,
            data_devolucao,
            horario_retirada,
            horario_devolucao,
            dias,
            tipo_produto_id,
            checkout_misto,
        };
        itens.push(item);

        //acessorios
        $(".js-input-acessorios:checked", context).each(function () {
            const timestamp = new Date().getTime();
            const acessorio_id = $(this).data("acessorio");
            const obrigatorio = $(this).data("obrigatorio");
            const produto_acessorio = $(this).val();
            const preco = $(this).data("preco");
            if (checkout_misto == "assinatura") {
                descricaoFaixa = "";
            }

            let checkout_misto_acessorio = null;
            if (checkout_misto == "simples") {
                checkout_misto_acessorio = "simples";
            }
            let dias = diasAcessorios;
            if ($(this).data("servico")) {
                dias = 1;
            }

            itens.push({
                ...item,
                id: timestamp + acessorio_id,
                produto_id: produto_acessorio,
                preco,
                acessorio_id,
                produto_principal: produto,
                faixa_id: null,
                data_devolucao:
                    calculoAcessorio && !$(this).data("servico")
                        ? item.data_devolucao
                        : item.data_retirada,
                horario_devolucao:
                    calculoAcessorio && !$(this).data("servico")
                        ? item.horario_devolucao
                        : item.horario_retirada,
                dias: dias,
                checkout_misto: checkout_misto_acessorio,
                descricao_faixa: descricaoFaixa,
                obrigatorio,
            });
        });

        $(button).addClass("js-loading-btn");

        axios
            .post(siteUrl("/carrinho/adicionar"), { produtos: itens })
            .then((response) => {
                window.location.href = url;
            })
            .catch((error) => {
                $(button).removeClass("js-loading-btn");
                $(button).attr("disabled");
            });
    };

    // Adicionar ao carrinho
    $(".js-btn-add-cart", context).on("click", function () {
        window.addToCart(context, this);
    });

    // Calcula Frete
    $(".js-calcula-frete", context).on("click", function () {
        const cep = $(".js-input-frete", context).val();
        $(".js-sem-entrega").addClass("d-none");
        $(".js-frete-itens", context).addClass("d-none");

        if (cep && cep.length == 9) {
            const produto = $(".js-preco-produto", context).data("produto");
            calculaFreteProduto(cep, produto);
        } else {
            $(".js-sem-cep").removeClass("d-none");
            $(".shipping__results", context).removeClass("d-none");
        }
    });
    if ($(".js-input-frete", context).val()) {
        setTimeout(function () {
            if ($(".js-calcula-frete", context).length) {
                $(".js-calcula-frete", context).trigger("click");
            } else {
                $(".js-calcula-frete-carrinho", context).trigger("click");
            }
        }, 500);
    }

    $(".js-calcula-frete-carrinho", context).on("click", function () {
        const cep = $(".js-input-frete", context).val();
        $(".js-sem-entrega").addClass("d-none");
        $(".js-opcoes-entrega", context).addClass("d-none");

        if (cep && cep.length == 9) {
            calculaFreteCarrinho(cep);
        } else {
            $(".js-sem-cep").removeClass("d-none");
            $(".shipping__results", context).removeClass("d-none");
        }
    });

    $(".js-seleciona-frete", context).on("change", function () {
        const frete = parseFloat($(this).data("valor"));
        const total = parseFloat($(".js-total-carrinho").data("total"));
        $(".js-total-carrinho span").html(
            numeral(total + frete).format("$ 0,0.00")
        );

        $("#overlayer-loading").addClass("show-overlayer");
        const dados = {
            cep: $(".js-input-frete").val(),
            entrega: $(this).val(),
            json: true,
        };
        avisosCupom();
        $.ajax({
            url: siteUrl("/frete"),
            type: "post",
            data: dados,
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                //location.reload();
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
            },
        });

    });

    $(".visualizar-face, .refazer-face", context).on("click", function (e) {
        e.preventDefault();
        const video = document.querySelector("#video");
        const canvas = document.querySelector("#canvas");

        $(".js-continuar-validacao", context)
            .addClass("d-none")
            .attr("disabled", "disabled");

        $(".js-nova-foto", context).addClass("d-none");
        $(".js-bloco-video", context).removeClass("d-none");
        $(canvas).addClass("d-none");

        video.setAttribute("autoplay", "");
        video.setAttribute("muted", "");
        video.setAttribute("playsinline", "");

        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices
                .getUserMedia({
                    video: {
                        width: 600,
                        height: 600,
                    },
                })
                .then(function (mediaStream) {
                    video.srcObject = mediaStream;
                    video.play();
                    $(".visualizar-face", context).addClass("d-none");
                    $("#captura-face", context)
                        .removeAttr("disabled")
                        .removeClass("d-none");

                    $("#captura-face", context).on("click", function (e) {
                        canvas.height = video.videoHeight;
                        canvas.width = video.videoWidth;

                        const foto = canvas.getContext("2d");
                        foto.drawImage(video, 0, 0);

                        $("#overlayer-loading").addClass("show-overlayer");
                        //$(".js-input-foto").val(canvas.toDataURL("image/jpeg"));

                        canvas.toBlob((blob) => {
                            let file = new File([blob], "fileName.jpg", {
                                type: "image/jpeg",
                            });
                            Vapor.store(file, {
                                baseURL: siteUrl("/site"),
                                progress: (progress) => {
                                    this.uploadProgress = Math.round(
                                        progress * 100
                                    );
                                },
                            })
                                .then((response) => {
                                    $(".js-input-foto").val(response.uuid);

                                    $("#overlayer-loading").removeClass(
                                        "show-overlayer"
                                    );
                                    $(".js-continuar-validacao", context)
                                        .removeClass("d-none")
                                        .removeAttr("disabled");
                                })
                                .catch((error) => {
                                    alert(
                                        "Aconteceu um erro ao tentar salvar a imagem!"
                                    );
                                });
                        }, "image/jpeg");

                        $(".js-bloco-video", context).addClass("d-none");
                        $(canvas).removeClass("d-none");

                        $("#captura-face", context)
                            .attr("disabled", "disabled")
                            .addClass("d-none");
                        $(".js-nova-foto", context).removeClass("d-none");

                        mediaStream.getTracks().forEach(function (track) {
                            track.stop();
                        });
                    });
                })
                .catch(function (err) {
                    console.log("Não há permissões para acessar a webcam");
                    $("#alert-no-permission-webcam").removeClass("d-none");
                });
        } else {
            console.log("não suportado");
        }
    });

    if ($(".js-contrato-checkout", context).length) {
        const codigoContrato = $(".js-contrato-checkout", context).data(
            "codigo"
        );
        const demonstracao = parseInt(window.demonstracao);
        // Carrega o widget embedded com a request_signature_key
        var widget = new Clicksign(codigoContrato);

        if (window.isProduction && demonstracao != 1) {
            widget.endpoint = "https://app.clicksign.com";
        } else {
            widget.endpoint = "https://sandbox.clicksign.com";
        }
        widget.mount("js-container-contrato");
        widget.on("signed", function () {
            $("#js-form-contrato", context).trigger("submit");
        });
    }

    if ($(".js-setup-iugu", context).length) {
        $(".js-setup-iugu", context).trigger("click");
    }

    if ($(".js-continuar-pagamento", context).length) {
        validaFormPagamento(context);
    }

    $(".js-verifica-pagamento", context).on("change", function () {
        validaFormPagamento(context);
        avisosCupom();
        $("#overlayer-loading").addClass("show-overlayer");
        const dados = {
            entrega: $("input[name=entrega]:checked").val(),
            cep: $("#cep_entrega").val(),
        };
        $.ajax({
            url: siteUrl("/frete-temporario"),
            type: "post",
            data: dados,
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                //location.reload();
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
            },
        });
    });
    function avisosCupom() {
        $(".js-btn-cupom").prop("disabled", false);
        if (!$(".js-aviso-desconto").hasClass("d-none")) {
            $(".js-aviso-desconto").addClass("d-none");
        }
    }

    if ($(".js-trocar-cartao", context).length) {
        startIuguSetup();
        $(".js-continuar-pagamento", context).removeAttr("disabled");
    }

    $(".js-pagamento-cartao", context).on("change", function () {
        if (this.value == "novo-cartao") {
            $(".js-novo-cartao", context).removeClass("d-none");
        } else {
            $(".js-novo-cartao", context).addClass("d-none");
        }
    });

    $(".js-continuar-pagamento", context).on("click", function (e) {
        e.preventDefault();
        $(this).attr("disabled", "disabled");

        const pagamento = $(".js-checkbox-pagamento input:checked", context);
        const trocarCartao = $(".js-trocar-cartao", context);
        const cartaoSalvo = $(".js-cartao-salvo:checked", context).val();

        const pagamentoCaucao = $(".js-checkbox-caucao input:checked", context);
        const comCaucao = parseFloat($("#js-config-pagamentos-com-caucao", context).val());
        const valorCaucao = parseFloat($("#js-valor-caucao", context).val());

        if (comCaucao && valorCaucao > 0 && !$(pagamentoCaucao).length) {
            $(".js-alert-pagamento-caucao", context).removeClass("d-none");
            $(".js-continuar-pagamento", context).removeAttr("disabled");
            return;
        }

        if (
            (
                $(pagamento).length &&
                $(pagamento).data("tipo") == "credito" &&
                cartaoSalvo == undefined
            ) ||
            (
                $(pagamentoCaucao).length &&
                $(pagamentoCaucao).data("tipo") == "credito" &&
                cartaoSalvo == undefined
            ) ||
            trocarCartao.length
        ) {
            const token = processaDadosCartao(context);
            if (!token) {
                $(".js-continuar-pagamento", context).removeAttr("disabled");
            }
        } else {
            $("#overlayer-loading").addClass("show-overlayer");
            $(".js-form-pagamento", context).trigger("submit");
        }
    });

    $(".js-data-retirada-checkout", context).on("change", function () {
        let data = this.value;
        const regex = /\d{4}-\d{2}-\d{2}/;
        if (regex.test(data)) {
            data = moment(data).format("DD/MM/YYYY");
        }
        let retirada = moment(data, "DD/MM/YYYY");
        let hoje = moment(new Date()).format("DD/MM/YYYY");
        if (moment(retirada).isBefore(moment(hoje, "DD/MM/YYYY"))) {
            $(".js-locais-retirada", context).html(
                '<div class="alert alert-warning mt-2">Você não pode selecionar uma data no passado.</div>'
            );
            return;
        }

        $("#js-data-retirada-selecionada", context).val(data);
        $("#js-horario-retirada-selecionado", context)
            .val("")
            .trigger("change");

        $(".js-data-escolhida").html(`<strong>Data</strong>: ${data} <br>`);

        $("#overlayer-loading").addClass("show-overlayer");
        axios
            .post(siteUrl("/checkout/locais-retirada"), { data })
            .then((response) => {
                $(".js-locais-retirada", context).html(response.data);

                Eloca.load($(".js-locais-retirada", context));

                $("#overlayer-loading").removeClass("show-overlayer");
            })
            .catch((error) => {
                $("#overlayer-loading").removeClass("show-overlayer");
            });
    });

    $(".js-horario-retirada-checkout", context).on("change", function () {
        $("#js-horario-retirada-selecionado").val(this.value).trigger("change");
        let formCheck = $(this).closest(".js-form-check");
        $("li.card-selected", formCheck).removeClass("card-selected");
        $(this, formCheck)
            .closest(".checkout__radio")
            .addClass("card-selected");
    });

    $("#js-horario-retirada-selecionado", context).on("change", function () {
        validaFormPagamento(context);
    });

    $(".js-btn-whatsapp", context).on("click", function () {
        const url = $(this).data("href");
        const rota = $(this).data("rota");
        const ads = $(this).data("ads");
        const adsRotulo = $(this).data("ads-rotulo");
        $.ajax({
            type: "get",
            url: rota,
            success: function (res) {
                //console.log(res);
            },
        });
        if (window.isProduction) {
            gtag("event", "clique", {
                event_category: "clique",
                event_label: "chat_whatsapp",
                event_action: "clique",
            });
        }
        if (ads && adsRotulo) {
            gtag("event", "conversion", {
                send_to: `${ads}/${adsRotulo}`,
            });
        }
        window.open(url);
    });

    $(".js-btn-cupom", context).on("click", function () {
        const cupom = $(".js-input-cupom", context).val();
        if (cupom.length >= 3) {
            $(".js-input-cupom", context).removeClass("is-invalid");
            $("#overlayer-loading").addClass("show-overlayer");
            $.ajax({
                url: siteUrl("/carrinho/valida-cupom"),
                type: "get",
                data: { cupom },
                success: function (res) {
                    //$("#overlayer-loading").removeClass("show-overlayer");
                    location.reload();
                },
                error: function (err) {
                    const data = err.responseJSON;
                    const alertInvalido = $(".js-msg-cupom-invalido", context);
                    if (data.message) {
                        $(".js-msg", alertInvalido).text(data.message);
                    } else {
                        $(".js-msg", alertInvalido).text(
                            "Cupom inválido, tente novamente."
                        );
                    }
                    $(".js-codigo-cupom", alertInvalido).text(cupom);
                    $(alertInvalido).removeClass("d-none");
                    $("#overlayer-loading").removeClass("show-overlayer");
                },
            });
        } else {
            $(".js-input-cupom", context).addClass("is-invalid");
        }
    });

    if ($(".js-bloco-acessorio", context).length) {
        $(".js-bloco-acessorio", context).each(function () {
            const bloco = this;
            if ($(".js-input-acessorios", bloco).data("obrigatorio")) {
                $(".js-input-acessorios", bloco).trigger("change");
            }
        });
    }

    $(".js-sem-numero", context).on('click', function () {
        $("#numero", context).val("s/n");
    });

    function validaFormPagamento(context) {
        const entrega = $(".js-checkbox-entrega input:checked", context);
        const pagamento = $(".js-checkbox-pagamento input:checked", context);
        const pagamentoCaucao = $(".js-checkbox-caucao input:checked", context);
        const comCaucao = parseFloat($("#js-config-pagamentos-com-caucao", context).val());

        let desconto = 0;
        let descricaoDesconto = "";
        if ($(pagamento).length) {
            if ($(pagamento).data("desconto")) {
                desconto = parseFloat(
                    $(pagamento).data("desconto").replace(",", ".")
                );
            }
            descricaoDesconto = $(pagamento).data("descricao");
        }
        const subtotal = parseFloat($(".js-subtotal-checkout").data("total"));

        let valorDesconto = 0;
        if (desconto > 0) {
            valorDesconto = (subtotal * desconto) / 100;
        }

        console.log('valida forma pagamento', $(pagamento), $(pagamentoCaucao));
        if (
            ($(pagamento).length && $(pagamento).data("tipo") == "credito") ||
            ($(pagamentoCaucao).length && $(pagamentoCaucao).data("tipo") == "credito")
        ) {
            $(".js-form-cartao", context).removeClass("d-none");
            startIuguSetup();
        } else {
            $(".js-form-cartao", context).addClass("d-none");
        }
        if ($(pagamento).length && $(pagamento).data("tipo") == "boleto") {
            $(".js-alert-boleto", context).removeClass("d-none");
        } else {
            $(".js-alert-boleto", context).addClass("d-none");
        }

        if (comCaucao) {
            validaPagamentoCaucao(context, pagamento);
        }

        let validaLocalRetirada = true;
        if (
            $(entrega).length &&
            $(entrega).data("tipo") == 2 &&
            $(entrega).data("local-retirada")
        ) {
            $(".js-bloco-locais-retirada").removeClass("d-none");
            if (
                !$("#js-data-retirada-selecionada").val() ||
                !$("#js-horario-retirada-selecionado").val()
            ) {
                validaLocalRetirada = false;
            }
        } else {
            $(".js-bloco-locais-retirada").addClass("d-none");
        }

        if ($(entrega).length && $(pagamento).length && validaLocalRetirada) {
            $(".js-continuar-pagamento", context).removeAttr("disabled");
        } else {
            $(".js-continuar-pagamento", context).attr("disabled", "disabled");
        }

        let frete = 0;
        if (
            $('.js-verifica-pagamento[name="entrega"]:checked', context).length
        ) {
            frete = parseFloat(
                $(
                    '.js-verifica-pagamento[name="entrega"]:checked',
                    context
                ).data("frete")
            );
        }

        const total = parseFloat($(".js-total-checkout").data("total"));
        $(".js-total-checkout span").html(
            numeral(total + frete - valorDesconto).format("$ 0,0.00")
        );
        if ($(".js-total-mensal-checkout").length) {
            const totalMensal = parseFloat(
                $(".js-total-mensal-checkout").data("total")
            );
            $(".js-total-mensal-checkout span").html(
                numeral(totalMensal - valorDesconto).format("$ 0,0.00")
            );
        }
        $(".js-valor-frete").html(numeral(frete).format("$ 0,0.00"));

        if (valorDesconto > 0) {
            if ($(".js-desconto-geral-checkout").length) {
                $(".js-desconto-geral-checkout", context).replaceWith(`
                    <li class="list-group-item js-desconto-geral-checkout">
                        Desconto - ${descricaoDesconto}
                        <span class="text-success">R$ ${valorDesconto
                            .toFixed(2)
                            .replace(".", ",")}</span>
                    </li>
                `);
            } else {
                $(".js-subtotal-checkout", context).after(`
                    <li class="list-group-item js-desconto-geral-checkout">
                        Desconto - ${descricaoDesconto}
                        <span class="text-success">R$ ${valorDesconto
                            .toFixed(2)
                            .replace(".", ",")}</span>
                    </li>
                `);
            }
        } else {
            $(".js-desconto-geral-checkout").remove();
        }
    }

    function validaPagamentoCaucao(context, pagamento)
    {
        if ($(pagamento).length && $(pagamento).data("tipo") == "credito") {
            $(".js-checkbox-caucao #mesmo-cartao", context).removeAttr("disabled");
            $(".js-checkbox-caucao #mesmo-cartao", context).closest(".card-custom-radio").removeClass('card-disabled');

            $(".js-checkbox-caucao #caucao-credito", context).attr("disabled", "disabled");
            $(".js-checkbox-caucao #caucao-credito", context).prop("checked", false);
            $(".js-checkbox-caucao #caucao-credito", context).closest(".card-custom-radio").removeClass('card-selected').addClass('card-disabled');

            $(".js-parcelas-cartao", context).removeClass("d-none");
        } else if ($(pagamento).length && $(pagamento).data("tipo") == "pix") {
            $(".js-checkbox-caucao #caucao-credito", context).removeAttr("disabled");
            $(".js-checkbox-caucao #caucao-credito", context).closest(".card-custom-radio").removeClass('card-disabled');

            $(".js-checkbox-caucao #mesmo-cartao", context).attr("disabled", "disabled");
            $(".js-checkbox-caucao #mesmo-cartao", context).prop("checked", false);
            $(".js-checkbox-caucao #mesmo-cartao", context).closest(".card-custom-radio").removeClass('card-selected').addClass('card-disabled');

            $(".js-parcelas-cartao", context).addClass("d-none");
        } else {
            $(".js-checkbox-caucao #caucao-credito", context).attr("disabled", "disabled");
            $(".js-checkbox-caucao #caucao-credito", context).prop("checked", false);
            $(".js-checkbox-caucao #caucao-credito", context).closest(".card-custom-radio").removeClass('card-selected').addClass('card-disabled');

            $(".js-checkbox-caucao #mesmo-cartao", context).attr("disabled", "disabled");
            $(".js-checkbox-caucao #mesmo-cartao", context).prop("checked", false);
            $(".js-checkbox-caucao #mesmo-cartao", context).closest(".card-custom-radio").removeClass('card-selected').addClass('card-disabled');
        }
    }

    function startIuguSetup() {
        checkTrackingBlock();
        Iugu.setAccountID(window.accountIdIugu);
        const demonstracao = parseInt(window.demonstracao);
        if (! window.isProduction || demonstracao == 1) {
            Iugu.setTestMode(true);
        }
        Iugu.setup();
    }

    function processaDadosCartao(context) {
        const numero = $(".credit_card_number", context).val();
        const nome = $(".credit_card_name", context).val();
        const data = $(".credit_card_expiration", context).val();
        const cvv = $(".credit_card_cvv", context).val();
        const bandeira = Iugu.utils.getBrandByCreditCardNumber(numero);
        if (!Iugu.utils.validateCreditCardNumber(numero)) {
            $(".credit_card_number", context).addClass("input-invalid-value");
            return false;
        }
        $(".credit_card_number", context)
            .removeClass("input-invalid-value")
            .addClass("input-valid-value");

        if (!nome) {
            $(".credit_card_name", context).addClass("input-invalid-value");
            return false;
        }
        $(".credit_card_name", context)
            .removeClass("input-invalid-value")
            .addClass("input-valid-value");

        if (!Iugu.utils.validateExpirationString(data)) {
            $(".credit_card_expiration", context).addClass(
                "input-invalid-value"
            );
            return false;
        }
        $(".credit_card_expiration", context)
            .removeClass("input-invalid-value")
            .addClass("input-valid-value");

        if (!Iugu.utils.validateCVV(cvv, bandeira)) {
            $(".credit_card_cvv", context).addClass("input-invalid-value");
            return false;
        }
        $(".credit_card_cvv", context)
            .removeClass("input-invalid-value")
            .addClass("input-valid-value");

        const form = document.querySelector(".js-form-pagamento", context);

        $("#overlayer-loading").addClass("show-overlayer");
        try {
            Iugu.createPaymentToken(form, function (response) {
                if (!response.errors) {
                    $("#js-token-iugu", context).val(response.id);
                    $("#js-card-iugu", context).val(
                        response.extra_info.display_number
                    );

                    $("input.credit_card_number", context).removeAttr("name");
                    $("input.credit_card_name", context).removeAttr("name");
                    $("input.credit_card_expiration", context).removeAttr(
                        "name"
                    );
                    $("input.credit_card_cvv", context).removeAttr("name");

                    $(".js-form-pagamento", context).trigger("submit");
                } else {
                    $("#overlayer-loading").removeClass("show-overlayer");
                    $(".js-form-cartao input.input-valid-value")
                        .addClass("input-invalid-value")
                        .removeClass("input-valid-value");
                }
            });
        } catch (error) {
            $("#overlayer-loading").removeClass("show-overlayer");
            $(".js-form-cartao input.input-valid-value")
                .addClass("input-invalid-value")
                .removeClass("input-valid-value");
        }
    }

    function calculaPrecoSpot(context) {
        setTimeout(() => {
        let data_retirada = $(".js-data-retirada", context).val();
        let data_devolucao = $(".js-data-devolucao", context).val();

        if (data_retirada && data_devolucao) {
            const regex = /\d{4}-\d{2}-\d{2}/;
            if (!regex.test(data_retirada)) {
                data_retirada = moment(data_retirada, "D/M/YYYY");
            } else {
                data_retirada = moment(data_retirada);
            }
            if (!regex.test(data_devolucao)) {
                data_devolucao = moment(data_devolucao, "D/M/YYYY");
            } else {
                data_devolucao = moment(data_devolucao);
            }

            let dias = data_devolucao.diff(data_retirada, "days") + 1;
            if (window.location.host.indexOf("alugatudo") > -1)
                dias = data_devolucao.diff(data_retirada, "days");

            // Caso tenha uma excessão no calculo de dias
            // Usar este data-fator para armazenar o valor
            // neste momento ele substituira o calculo padrão do sistema

            const fator = $(".js-preco-produto", context).data("fator");
            const calculaPrecoAcessorio = $(".js-preco-produto", context).data(
                "calculo-acessorio"
            );

            if (fator != "") {
                dias = parseFloat(fator);
            }

            const preco = parseFloat(
                $(".js-preco-produto", context).data("preco")
            );

            let quantidade = 1;
            if ($("#product-qtd", context).length) {
                quantidade = parseInt($("#product-qtd", context).val());
            }

            let total = preco * dias * quantidade;
            $(".js-input-acessorios:checked", context).each(function () {
                let precoAcessorio = parseFloat($(this).data("preco"));
                if (calculaPrecoAcessorio && !$(this).data("servico")) {
                    precoAcessorio *= dias;
                }
                total += precoAcessorio;
            });

            $(".js-preco-total-spot", context).html(
                `<span class="preco-total-spot">Total: </span>${numeral(total).format("$ 0,0.00")}`
            );
            $(".js-preco-produto", context).data("dias", dias);

            const faixas = $(".js-preco-produto", context).data("faixas");
            const faixa_id = $(".js-preco-faixa-selecionar", context).val();
            if (faixas && !faixa_id) {
                $(".js-preco-faixa-selecionar", context).addClass("is-invalid");
                return;
            }
            $(".js-btn-add-cart", context).removeAttr("disabled");
        }
         }, 500);
    }

    function calculaPrecoAcessorios(context) {
        let faixa = "default";
        if ($(".js-preco-faixa-selecionar", context).length == 1) {
            faixa = $(
                ".js-preco-faixa-selecionar option:selected",
                context
            ).data("descricao");
        } else if ($(".js-preco-faixa-selecionar", context).length > 1) {
            faixa = $(".js-preco-faixa-selecionar:checked", context).data(
                "descricao"
            );
        }

        let total = parseFloat($(".js-preco-produto", context).data("preco"));
        $(".js-input-acessorios:checked", context).each(function () {
            const precos = $(this).data("precos");
            let preco = $(this).data("preco");
            if (precos && precos[faixa]) {
                preco = precos[faixa];
            }
            total += parseFloat(preco);
        });

        if (total > 0) {
            $(".js-preco-faixa-selecionada", context).removeClass("d-none");
            $(".js-total-produto", context).removeClass("d-none");
            $(".js-preco-faixa-selecionada .valor-preco-faixa-selecionada, .js-total-produto .valor-total-produto", context).html(
                    numeral(parseFloat(total)).format("$ 0,0.00")
            );
        }
    }

    function atualizaPrecosAcessorios(context) {
        let faixa = "default";
        if (
            $(".js-preco-faixa-selecionar", context).length == 1 &&
            $(".js-preco-faixa-selecionar option:selected", context).length
        ) {
            faixa = $(
                ".js-preco-faixa-selecionar option:selected",
                context
            ).data("descricao");
        } else if (
            $(".js-preco-faixa-selecionar", context).length > 1 &&
            $(".js-preco-faixa-selecionar:checked", context).length
        ) {
            faixa = $(".js-preco-faixa-selecionar:checked", context).data(
                "descricao"
            );
        }

        $(".js-input-acessorios", context).each(function () {
            const precos = $(this).data("precos");
            let preco = $(this).data("preco");
            if (precos && precos[faixa]) {
                preco = precos[faixa];
            }
            $(this).data("preco", preco);
            $(this)
                .closest(".js-bloco-acessorio")
                .find(".js-preco-acessorio-selecionado")
                .html(numeral(parseFloat(preco)).format("0,0.00"));
        });
    }

    function processaBotaoWhatsapp(
        context,
        whatsapp,
        quantidade,
        preco,
        data,
        data_retirada,
        data_devolucao,
        orcamento,
        button
    ) {
        const ads = $(button).data("ads");
        const adsRotulo = $(button).data("ads-rotulo");
        const url = "https://wa.me/";
        const quebra = "%0A";
        const produto = $(".js-product-title", context).text();
        let cep = $(".js-input-frete", context).val();
        if (cep == undefined || cep == "") {
            cep = null;
        }

        let total = numeral(parseFloat(preco)).format("$ 0,0.00");

        if ($(".js-total-produto", context).text()) {
            total = $(".js-total-produto", context)
                .text()
                .replace("Total: ", "");
        }
        if ($(".js-preco-faixa-selecionada .valor-preco-faixa-selecionada", context).text()) {
            total = $(".js-preco-faixa-selecionada .valor-preco-faixa-selecionada", context)
                .text();
        }
        if ($(".js-preco-total-spot").length ) {
            total = $(".js-preco-total-spot", context)
                .text()
                .replace("Total: ", "");
        }
        let faixa = "";
        let faixaValor = "";
        if ($(".js-descricao-faixa", context).length) {
            faixa = $(".js-descricao-faixa").text();
        }
        if ($(".js-preco-faixa-selecionar", context).length == 1) {
            faixaValor = $(
                ".js-preco-faixa-selecionar option:selected",
                context
            ).text();
        } else if ($(".js-preco-faixa-selecionar", context).length > 1) {
            faixaValor = $(".js-preco-faixa-selecionar:checked", context)
                .closest(".js-mini-bloco")
                .find(".js-descricao")
                .text();
        }
        let acessorios = [];
        $(".js-input-acessorios:checked", context).each(function () {
            acessorios.push(
                $(this)
                    .closest(".js-bloco-acessorio")
                    .find(".js-nome-acessorio")
                    .text()
            );
        });

        if (orcamento) {
            faixaValor = 0;
        }

        let text =
            `*Pedido Ecommerce* ${quebra} ------------------------------ ${quebra}` +
            `Produto: ${quebra}*${quantidade} x ${produto.trim()}* ${quebra} ${quebra}`;

        if (faixa && faixaValor) {
            text += `${faixa.trim()}: ${quebra}*${faixaValor.trim()}* ${quebra} ${quebra}`;
        }

        text += `Acessórios: ${quebra}`;
        if (acessorios.length) {
            acessorios.forEach((item) => {
                text += `*${item.trim()}* ${quebra}`;
            });
            text += quebra;
        } else {
            text += `*Nenhum* ${quebra} ${quebra}`;
        }

        if (!orcamento) {
            text += `Total:${quebra}*${total.trim()}* ${quebra} ${quebra}`;
        }

        if (data != undefined) {
            text += `Data:${quebra}*${data}* ${quebra} ${quebra}`;
        }

        if (data_retirada != undefined && data_devolucao != undefined) {
            text += `Período:${quebra}*${data_retirada} a ${data_devolucao}* ${quebra} ${quebra}`;
        }

        if (cep) {
            text += `CEP:${quebra}*${cep}* ${quebra} ${quebra}`;
        }
        text += `------------------------------ ${quebra}`;

        const produtoId = $(".js-preco-produto", context).data("produto");
        $.ajax({
            type: "get",
            url: siteUrl(`/carrinho/compra-whatsapp/${produtoId}`),
            success: function (res) {
                //console.log(res);
            },
        });
        if (ads && adsRotulo) {
            gtag("event", "conversion", {
                send_to: `${ads}/${adsRotulo}`,
                value: total.trim(),
                currency: "BRL",
            });
        }
        if (window.isProduction) {
            gtag("event", "clique", {
                event_category: "clique",
                event_label: "comprar_whatsapp",
                event_action: "clique",
            });
        }

        const link = url + whatsapp + "?text=" + text;
        window.open(link);
    }

    async function calculaFreteProduto(cep, produtoId) {
        $(".js-sem-cep").addClass("d-none");

        $(".shipping__results", context).removeClass("d-none");
        $(".js-spinner", context).removeClass("d-none");
        $(".button-text", context).addClass("d-none");

        const { data } = await axios.get(
            siteUrl(`/busca-frete/${cep}?produtoId=${produtoId}`)
        );

        $(".js-spinner", context).addClass("d-none");
        $(".button-text", context).removeClass("d-none");

        if (data.length) {
            let html = "";
            data.forEach(function (item) {
                if (item.propria) {
                    let valor = numeral(parseFloat(item.valor)).format(
                        "$ 0,0.00"
                    );
                    if (item.gratis) {
                        valor = "Grátis";
                    }
                    let prazo = `${item.prazo} dia útil`;
                    if (item.prazo > 1) {
                        prazo = `${item.prazo} dias úteis`;
                    }
                    html += `
                        <div class="row no-gutters g-0 result">
                            <div class="col-3 col-md-3 result__price">${valor}</div>
                            <div class="col-4 col-md-3 result__deadline">${prazo}</div>
                            <div class="col-5 col-md-6 result__type">${item.descricao}</div>
                        </div>
                    `;
                } else {
                    let linkModal = "";
                    if (item.retirada) {
                        linkModal =
                            '- <button type="button" class="btn btn-link btn-ver-locais js-modal-retirada" data-bs-toggle="modal" data-bs-target="#locaisRetirada" data-toggle="modal" data-target="#locaisRetirada">ver locais de retirada</button>';
                    }
                    let valor = "R$ 0,00";
                    if (item.valor) {
                        valor = numeral(parseFloat(item.valor)).format(
                            "$ 0,0.00"
                        );
                    }
                    let prazo = "-";
                    if (item.prazo) {
                        prazo = `${item.prazo} dia útil`;
                        if (item.prazo > 1) {
                            prazo = `${item.prazo} dias úteis`;
                        }
                    }
                    html += `<div class="row no-gutters g-0 result">
                                <div class="col-3 col-md-3 result__price">${valor}</div>
                                <div class="col-4 col-md-3 result__deadline">${prazo}</div>
                                <div class="col-5 col-md-6 result__type">
                                    ${item.descricao} ${linkModal}
                                </div>
                             </div>`;
                }
            });
            $(".js-frete-itens", context).html(html);
            $(".js-frete-itens", context).removeClass("d-none");
        } else {
            $(".js-frete-itens", context).addClass("d-none");
            $(".js-sem-entrega", context).removeClass("d-none");
        }
    }

    async function calculaFreteCarrinho(cep) {
        $(".js-sem-cep").addClass("d-none");

        $(".shipping__results", context).removeClass("d-none");
        $(".js-spinner", context).removeClass("d-none");
        $(".button-text", context).addClass("d-none");

        const { data } = await axios.get(
            siteUrl(`/busca-frete/${cep}?carrinho=1`)
        );

        $(".js-spinner", context).addClass("d-none");
        $(".button-text", context).removeClass("d-none");

        if (data.length) {
            let html = "";
            data.forEach(function (item) {
                if (item.propria) {
                    let valor = numeral(parseFloat(item.valor)).format(
                        "$ 0,0.00"
                    );
                    let frete = item.valor;
                    if (item.gratis) {
                        valor = "Grátis";
                        frete = 0;
                    }
                    let prazo = `${item.prazo} dia útil`;
                    if (item.prazo > 1) {
                        prazo = `${item.prazo} dias úteis`;
                    }
                    html += `
                        <li>
                            <label for="entrega-${item.tipo}-${item.entrega_propria_faixa_id}">
                                <input class="js-seleciona-frete" data-valor="${frete}" type="radio" id="entrega-${item.tipo}-${item.entrega_propria_faixa_id}"
                                    name="entrega" value="${item.tipo}-${item.entrega_propria_id}-${item.entrega_propria_faixa_id}">
                                <span class="result__price">${valor}</span>
                                <span class="result__deadline">${prazo}</span>
                                <span class="result__type">${item.descricao}</span>
                            </label>
                        </li>
                    `;
                } else {
                    let linkModal = "";
                    if (item.retirada) {
                        linkModal =
                            '- <button type="button" class="btn btn-link btn-ver-locais js-modal-retirada" data-bs-toggle="modal" data-bs-target="#locaisRetirada">ver locais</button>';
                    }
                    let frete = 0;
                    let valor = "R$ 0,00";
                    if (item.valor) {
                        valor = numeral(parseFloat(item.valor)).format(
                            "$ 0,0.00"
                        );
                        frete = item.valor;
                    }
                    let prazo = "-";
                    if (item.prazo) {
                        prazo = `${item.prazo} dia útil`;
                        if (item.prazo > 1) {
                            prazo = `${item.prazo} dias úteis`;
                        }
                    }
                    let id = 0;
                    if (item.id) {
                        id = item.id;
                    }
                    html += `
                        <li>
                            <label for="entrega-${item.tipo}-${id}">
                                <input class="js-seleciona-frete" data-valor="${frete}" type="radio" id="entrega-${item.tipo}-${id}"
                                    name="entrega" value="${item.tipo}">
                                <span class="result__price">${valor}</span>
                                <span class="result__deadline">${prazo}</span>
                                <span class="result__type">${item.descricao} ${linkModal}</span>
                            </label>
                        </li>
                    `;
                }
            });
            $(".js-opcoes-entrega ul", context).html(html);
            $(".js-opcoes-entrega", context).removeClass("d-none");

            Eloca.load($(".js-opcoes-entrega", context));
        } else {
            $(".js-opcoes-entrega", context).addClass("d-none");
            $(".js-sem-entrega", context).removeClass("d-none");
        }
    }

    function checkTrackingBlock() {
        if (!checkTrackingBlock.promise) {
            checkTrackingBlock.promise = new Promise(function (
                resolve,
                reject
            ) {
                var time = Date.now();
                var img = new Image();
                img.onload = resolve;
                img.onerror = function () {
                    if (Date.now() - time < 50) {
                        reject(new Error("Rejected."));
                    } else {
                        resolve(new Error("Takes too long."));
                    }
                };
                img.src = "//www.facebook.com/tr/";
            })
                .then((result) => {
                    return false;
                })
                .catch((e) => {
                    $("#alertAdblock").removeClass("d-none");
                });
        }
    }

    function verificaDataDevolucao(context) {
        const tipoLoja = $(".js-bloco-loja-mista input:radio:checked", context);
        if ($(tipoLoja).val() == "assinatura") {
            $(".js-data-devolucao-texto", context).html("");
            return;
        }
        let element = $(".js-preco-faixa-selecionar:checked", context);
        if ($(".js-preco-faixa-selecionar option:selected", context).length) {
            element = $(".js-preco-faixa-selecionar option:selected", context);
        }
        const duracao = $(element).data("duracao");
        let data = $(".js-data-programado", context).val();
        if (!data || !duracao) {
            return;
        }
        const regex = /\d{4}-\d{2}-\d{2}/;
        if (regex.test(data)) {
            data = moment(data).format("DD/MM/YYYY");
        }
        data = moment(data, "DD/MM/YYYY")
            .add(duracao, "days")
            .format("DD/MM/YYYY");

        $(".js-data-devolucao-texto", context).html(
            `<div class="bloco-data-devolucao"> Data de Devolução: ${data}</div>`
        );
    }
    const elementoEffect = document.querySelector(".js-digitar-texto");
    if (elementoEffect) {
        let textEffect = elementoEffect.innerHTML;
        textEffect = textEffect.replaceAll('<br>','\n');
        elementoEffect.innerHTML = "";
        let index = 0;

        function typeWriter() {
            if (index < textEffect.length) {
                elementoEffect.innerHTML += textEffect.charAt(index);
                index++;
                setTimeout(typeWriter, 100);
            }
        }

        typeWriter();
    }

    if ($(".js-custom-radio-option").length > 0) {

        $(context).on("click", ".js-custom-radio-option", function () {

            var token = $(this).val();

            if (token != 'sms' && token != 'whatsapp') {

                $(".js-telefone-token").removeAttr("required");
                $(".js-telefone-token").attr("disabled", true);
                $(".js-telefone-token").val("");
                $(".js-telefone-token").parent().hide();

            } else {

               $(".js-telefone-token").parent().show();
               $(".js-telefone-token").attr("required", true);
               $(".js-telefone-token").removeAttr("disabled");
            }
        });
    }

    if ($(".js-botao-continuar-testemunhas").length > 0) {

        $(context).on("click", ".js-botao-continuar-testemunhas", function () {

            var form = $("#js-form-contrato");

            if (form[0].checkValidity()) {

                $("#overlayer-loading").addClass("show-overlayer");

                setTimeout(function() {

                    $("#overlayer-loading").removeClass("show-overlayer");
                }, 17000);

            }

        });
    }

};
